import React, { memo } from 'react'
import { motion, useCycle } from "framer-motion"
import IconButton from '@mui/material/IconButton'

export const dOptions = {
  // CloseFullscreenIcon: { d: "M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2 22 3.41zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59 3.41 22z", viewBox: "0 0 24 24" },
  // OpenInFullIcon: { d: "M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10z", viewBox: "0 0 24 24" },
  // UnfoldLessIcon: { d: "M12 5.83 15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z", viewBox: "0 0 24 24" },
  // UnfoldMoreIcon: { d: "M7.41 18.59 8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z", viewBox: "0 0 24 24" },
  CloseFullscreenIcon: {
    d: "M22 3.41 L16.71 8.7 L20 12 H12 V4 L15.29 7.29 L20.59 2 L22 3.41 M3.41 22 L8.7 16.71 L12 20 V12 H4 L7.29 15.29 L2 20.59 L3.41 22 Z",
    viewBox: "0 0 24 24"
  },
  OpenInFullIcon: {
    d: "M21 11 V3 H13 L16.29 6.29 L6.29 16.29 L3 13 V21 H11 L7.71 17.71 L17.71 7.71 Z",
    viewBox: "0 0 24 24"
  },
  UnfoldLessIcon: {
    d: "M12 5.83 L15.17 9 L16.58 7.59 L12 3 L7.41 7.59 L8.83 9 L12 5.83 Z M12 18.17 L8.83 15 L7.41 16.41 L12 21 L16.59 16.41 L15.17 15 L12 18.17 Z",
    viewBox: "0 0 24 24"
  },
  UnfoldMoreIcon: {
    d: "M7.41 18.59 L8.83 20 L12 16.83 L15.17 20 L16.58 18.59 L12 14 L7.41 18.59 Z M16.59 5.41 L15.17 4 L12 7.17 L8.83 4 L7.41 5.41 L12 10 L16.59 5.41 Z",
    viewBox: "0 0 24 24"
  }
}

const BtnToggleMorphingD = ({ onClick, d1 = dOptions.CloseFullscreenIcon.d, d2 = dOptions.OpenInFullIcon.d, viewBox = dOptions.CloseFullscreenIcon.viewBox, }) => {

  // console.log('BtnToggleMorphingD d1, d2, viewBox:', d1, d2, viewBox)
  // const [ animate, cycle ] = useCycle(
  //   { d: d1 },
  //   { d: d2 }
  // )

  const [ d, cycleD ] = useCycle(d1, d2)

  return (
    <IconButton onClick={
      () => {
        onClick()
        cycleD()
      }}
      aria-label="Restore"
      style={{
        transition: 'none',
      }}
    >
      <svg
        focusable="false"
        aria-hidden="true"
        viewBox={viewBox}
        data-testid="CloseFullscreenIcon"
        style={{
          width: "1em",
          height: "1em",
          fill: "currentColor",
        }}
      >
        <motion.path
          animate={{ d: d }}
          transition={{
            ease: "easeInOut",
            duration: 1,
          }}
        />
      </svg>
    </IconButton>
  )
}

export default memo(BtnToggleMorphingD)



import React, { lazy, Suspense, useRef } from "react";
import useTheme from '@mui/material/styles/useTheme';
import Container from '@mui/material/Container';
import Box from "@mui/system/Box";
import { alpha } from "@mui/material";
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import FrameMaskForDiv from "../../_shared/FrameMaskForDiv";
import DropEffect from "../../_shared/dropEffect/DropEffect";
import { optionsDropEffect } from "../../_shared/dropEffect/DropEffect";
import imgLeaf1 from "../../../images/leaves/realLeaf1.png"
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";
import CursorDotRing from "../../_shared/CursorDotRing/CursorDotRing";
import WavedFrameAnimationForChildren from "../../_shared/WavedFrameAnimationForChildren";
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";
import PortalInBody from "../../_shared/PortalInBody";
import TocFm from "../../_shared/navigationInPage/TocFm";
import { POLYGON_RECTANGLE_TL_BR_NULL, POLYGON_RECTANGLE_TR_LB_NULL, POLYGON_RECTANGLE_LEFT_NULL, POLYGON_RECTANGLE_RIGHT_NULL, POLYGON_RECTANGLE, POLYGON_RECTANGLE_10_POINTS_1, POLYGON_STAR, POLYGON_RECTANGLE_10_POINTS_LEFT, POLYGON_RECTANGLE_10_POINTS_RIGHT, INSET_1_3, INSET_2_3, INSET_FULL } from "../../../utils/clipPathsConstants";
import IntroTextNacoWebStrankyCCh from './IntroTextNacoWebStrankyCCh';

const LazyWebPageGoodForVisitor = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyWebPageGoodForVisitor' */ './WebPageGoodForVisitor'));
const LazyIntroTextRychleWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyIntroTextRychleWebStrankyCCh' */ './IntroTextRychleWebStrankyCCh'));
const LazyIntroTextSpravneWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyIntroTextSpravneWebStrankyCCh' */ './IntroTextSpravneWebStrankyCCh'));
const LazyIntroTextMatPredstavuWebStrankyCCh = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyIntroTextMatPredstavuWebStrankyCCh' */ './IntroTextMatPredstavuWebStrankyCCh'));
const LazyTechStackWithData = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyTechStackWithData' */ '../../_shared/TechStackWithData'));
const LazyCircleZ = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyCircleZ' */ '../../_shared/CircleZ'));
const LazyTypewritingAbout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyTypewritingAbout' */ '../../_shared/TypewritingAbout'));
const LazyMultipleRowsInfiniteSlider = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: 'MultipleRowsInfiniteSliderLazy' */ `../../_shared/multipleRowsInfiniteSlider/MultipleRowsInfiniteSlider`)
);

const LazyLeavesAnimationAbs = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyLeavesAnimationAbs' */ "../../_shared/leavesAnimation/LeavesAnimationAbs"));
const LazyLeavesInDropAnimation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'LazyLeavesInDropAnimation' */ "../../_shared/leavesAnimation/LeavesInDropAnimation"));


const styleSxWrapMainUp = {
    "&.wrapMainUp": {
        // overflowX: 'hidden',
        position: 'relative',
        '& .cont': {
            px: [ 0.5, 1, 3, 4 ]
        },
        "& .toc": {
            transition: 'all 0.3s ease-in-out',
            marginTop: [ 0, 0.25, 0.5 ],
            marginBottom: [ 0.25, 0.5, 1 ],
            borderRadius: '3px',
            "& span": {
                display: "inline-block",
            },
            "&.inTOCView": {
                color: "info.contrastText",
                backgroundColor: "info.main",
                // transform: 'translateX(50%)',
                textAlign: "center",
            },
            "&.outTOCView": {
                color: "info.contrastText",
                backgroundColor: "info.main",
                // transform: 'translateX(0)',
                textAlign: "start",
            },
        },
        "& .wrapSep .toc": {
            marginTop: 0, // 'auto',
            paddingLeft: "20px",
        },
        "& .gridItem": {
            "& svg": {

                borderStyle: "solid",
            }
        },
        "& .cont3D": {
            position: 'relative',
            px: 0,
            py: [ 1, 1, 3, 4 ],
            "&:hover": {
                "& .paperSubt": {
                    transition: "all 0.5s",
                    transform: "rotate(0)",

                },
            },
            "& .paperSubt": {
                borderRadius: "5px", // ['5%','50%'],
                px: [ 1, 2 ],
                py: [ 1, 2 ],
                display: 'flex',
                flexDirection: 'column',
                bgcolor: "unset", // "primary.main",
                maxWidth: "fit-content",
                textAlign: "center",
                mx: "auto",
                // p: "5px",
                transition: "all 0.5s",
                // borderRadius: "5px",
                whiteSpace: "pre",
                "& .subTitle": {

                    px: [ 1, 2 ],
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "400",
                    textAlign: "center",
                },
            },
        },
        "& .contUp": {
            position: "relative",
            bgcolor: "primary.main",
            color: "primary.contrastText",
            background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
            pt: 0,
            pb: 0,
            px: [ 0.5, 1, 2, 3 ],
            transformStyle: "preserve-3d",
            "& .wrapTitleBoost": {
                width: "100%",
                perspective: "1000px",
            },
        },
    },

    "& .wrapLottImgs": {
        textAlign: "center",
        "& .imgLott": {
            height: "180px",
            width: "auto",
            borderRadius: "50%",
        }
    }
}

const MainUp = props => {

    const theme = useTheme()
    const breakpoint = useBreakpoint()
    const bgPrimaryDark = theme.palette.primary2.dark
    const refRychle = useRef(null)
    const refIdealne = useRef(null)
    const refWebPredstava = useRef(null)


    const namePaddingsFitXs = [ "xs", "sm" ].includes(breakpoint) ? "T20-R0-B20-L0" : "T20-R20-B20-L20"

    const sepFillColor = theme.palette.primary2.dark
    const sepFillColorContrastText = theme.palette.primary2.contrastText

    const optionsConnectTextsColors = {
        sepColors: { bgColor: theme.palette.primary.main, color: theme.palette.primary.contrastText },
        svgPathColor: theme.palette.primary.light,
        svgFillColor: theme.palette.primary.contrastText,
        startBg: theme.palette.primary.main,
        startColor: theme.palette.primary.contrastText,
        endBg: theme.palette.primary2.main,
        endColor: theme.palette.primary2.contrastText,
    }
    return (
        <Box
            sx={styleSxWrapMainUp}
            className="wrapMainUp"
        //     // style={{ height: '100vh',overflow: "scroll" }}
        //   style=  {{minHeight:"100vh"}}
        >
            <SeparatorAbsSvg2
                name="sepNormalRel2FullHorizontalHills"
                id="IntroTextNacoWebStrankyCCh"
                key="IntroTextNacoWebStrankyCCh"
                optionsSep={{
                    // dPathOrderNotIn: [ 0 ],
                    styleWrapSep: {
                        lineHeight: "inherit",
                        // height: "auto" 
                    },
                    styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
                    svgYPadding: 0,
                    isRelative: true,
                }}
            >
                <AnimateScrollIntoViewport
                    durationIn={0.5}
                    durationOut={0.2}
                    animateFramer={{ clipPath: INSET_FULL, }}
                    exitFramer={{ clipPath: INSET_1_3, }}
                    styleToMerge={{ clipPath: INSET_1_3, overflow: "inherit" }}
                    name="intro"
                    key="intro"
                >

                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,
                        }}
                    >
                        <Container maxWidth="xl" className='cont'>
                            <FrameMaskForDiv options={{
                                frames: [ "right", "bottom", "left" ],
                                masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                                rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
                            }} >
                                <IntroTextNacoWebStrankyCCh />
                            </FrameMaskForDiv>
                        </Container>
                    </div>
                </AnimateScrollIntoViewport>
            </SeparatorAbsSvg2>



            <div
                style={{
                    transform: "translateZ(0)",
                    // minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
                // ref={refRychle}
                // id="rychle2"
                key="wrapWebPageGoodForVisitor"
            >

                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="sepWebPageGoodForVisitor"
                    key="sepWebPageGoodForVisitor"
                    optionsSep={{
                        // dPathOrderNotIn: [ 0 ],
                        styleWrapSep: {
                            lineHeight: "inherit",
                            // height: "auto" 
                        },
                        styleSvg: { height: "50px", transform: 'rotateY(180deg)' },
                        svgYPadding: 0,
                        isRelative: true,
                    }}
                >



                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,

                        }}
                    >
                        <Container maxWidth="xl" className='cont'>
                            <FrameMaskForDiv options={{
                                frames: [ "right", "bottom", "left" ],
                                masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                                rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
                            }} >
                                <AnimateScrollIntoViewport
                                    durationIn={0.5}
                                    durationOut={0.2}
                                    animateFramer={{ clipPath: INSET_FULL, }}
                                    exitFramer={{ clipPath: INSET_1_3, }}
                                    styleToMerge={{ clipPath: INSET_1_3, overflow: "inherit" }}
                                    name="scrollWebPageGoodForVisitor"
                                    key="scrollWebPageGoodForVisitor"
                                >
                                    <Suspense
                                        // fallback={<div>Loading AnimScroll SeparatorAbsSvg2 Frame ...</div>}
                                        key="susWebPageGoodForVisitor">
                                        <LazyWebPageGoodForVisitor />
                                    </Suspense>
                                </AnimateScrollIntoViewport>
                            </FrameMaskForDiv>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>

            </div>

            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: INSET_FULL, }}
                exitFramer={{ clipPath: INSET_2_3, }}
                styleToMerge={{ clipPath: INSET_2_3, }}
                name="independentSepNormalRelDblFullHorizontal1"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRelDblFullHorizontal1"
                    id="independentSepNormalRelDblFullHorizontal1"
                    optionsSep={{
                        dPathOrderNotIn: [ 0, 1 ], heightsWanted: [ 8, 16, 32, 48, 64 ],
                        styleWrapSep: {
                            marginTop: 0,
                            marginBottom: 0,
                        }
                    }}
                    key="sepAfterNacoWebStrankyCCh"
                />
            </AnimateScrollIntoViewport>

            <div
                style={{
                    transform: "translateZ(0)",
                    // minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
                ref={refRychle}
                id="rychle2"
            >

                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="IntroTextRychleWebStrankyCCh"
                    passedControllingInViewport={false}
                    key="IntroTextRychleWebStrankyCCh"
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "100px",
                            // transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,

                        }}
                    >
                        <Container maxWidth="xl" className='cont' >
                            <FrameMaskForDiv
                                options={{
                                    frames: [ "right", "bottom", "left" ],
                                    masks: { top: "mask11", right: "mask11V", bottom: "mask11", left: "mask11V" },
                                    rightFr: { isCornerMask: false }, bottomFr: { isCornerMask: false }, leftFr: { isCornerMask: false }
                                }}
                            >
                                <AnimateScrollIntoViewport
                                    durationIn={0.5}
                                    durationOut={0.2}
                                    animateFramer={{
                                        clipPath: POLYGON_RECTANGLE,
                                        // backgroundColor: alpha(bgPrimaryDark, 0),
                                    }}
                                    exitFramer={{
                                        clipPath: POLYGON_RECTANGLE_TR_LB_NULL, //POLYGON_STAR, 
                                        // backgroundColor: alpha(bgPrimaryDark, 0.2),
                                    }}
                                    styleToMerge={{
                                        clipPath: POLYGON_RECTANGLE_TR_LB_NULL, // POLYGON_STAR,
                                        //  backgroundColor: alpha(bgPrimaryDark, 0.2),
                                    }}
                                    name="scrollRychle"
                                    key="scrollRychle"
                                // refParent={refRychle}
                                >
                                    <Suspense
                                        // fallback={<div>Loading Sep FrameMask AnimRef ...</div>}
                                        key="susIntroTextRychleWebStrankyCCh">
                                        <LazyIntroTextRychleWebStrankyCCh />
                                    </Suspense>
                                </AnimateScrollIntoViewport>
                            </FrameMaskForDiv>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>

            </div>
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: INSET_FULL, }}
                exitFramer={{ clipPath: INSET_2_3, }}
                styleToMerge={{ clipPath: INSET_2_3, }}
                name="sepAfterIntro1"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRelDblFullHorizontal1"
                    id="independentSepNormalRelDblFullHorizontal2"
                    optionsSep={{
                        dPathOrderNotIn: [ 0, 1 ], heightsWanted: [ 8, 16, 32, 48, 64 ],
                        styleWrapSep: {
                            marginTop: 0,
                            marginBottom: 0,
                        }
                    }}
                    key="sepAfterIntro1"
                />
            </AnimateScrollIntoViewport>

            <div style={{
                transform: "translateZ(0)",
                // minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
                id="idealne2"
                ref={refIdealne}
                key="IntroTextSpravneWebStrankyCCh"
            >

                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="IntroTextSpravneWebStrankyCCh"
                    passedControllingInViewport={false}
                    key="IntroTextSpravneWebStrankyCCh"
                    optionsSep={{
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,
                        }}
                    >
                        <WavedFrameAnimationForChildren
                            name="wavedSpravne"
                            namePaddings={namePaddingsFitXs}
                            stylesTop={[ { stroke: theme.palette.success.main, fill: theme.palette.warning.main }, { stroke: theme.palette.success.main, fill: theme.palette.info.main }, { stroke: theme.palette.success.main, fill: theme.palette.secondary.main }, { stroke: theme.palette.success.main, fill: theme.palette.secondary2.main }, { stroke: theme.palette.success.main, fill: theme.palette.primary2.main }, { stroke: theme.palette.success.main, fill: theme.palette.primary.dark } ]}
                            key="wavedFr Tvorba-web-stranok"
                        >
                            <Container maxWidth="xl" className='cont'>
                                <FrameMaskForDiv
                                    options={{
                                        frames: [ "right", "bottom", "left" ],
                                        masks: { top: "mask21TL", right: "mask21TR", bottom: "mask21BR", left: "mask21BLa" },
                                        rightFr: { isCornerMask: true }, bottomFr: { isCornerMask: true }, leftFr: { isCornerMask: true }
                                    }}
                                >
                                    <AnimateScrollIntoViewport
                                        durationIn={0.5}
                                        durationOut={0.2}
                                        animateFramer={{
                                            clipPath: POLYGON_RECTANGLE, // POLYGON_RECTANGLE_10_POINTS_LEFT,
                                            //  backgroundColor: alpha(bgPrimaryDark, 0), 
                                        }}
                                        exitFramer={{
                                            clipPath: POLYGON_RECTANGLE_TL_BR_NULL, // POLYGON_STAR, 
                                            // backgroundColor: alpha(bgPrimaryDark, 0.2),
                                        }}
                                        styleToMerge={{
                                            clipPath: POLYGON_RECTANGLE_TL_BR_NULL, // POLYGON_STAR, 
                                            // backgroundColor: alpha(bgPrimaryDark, 0.2),
                                        }}

                                        // POLYGON_RECTANGLE_TL_BR_NULL
                                        name="maskSpravne"
                                        refParent={refIdealne}
                                    >
                                        <Suspense
                                        // fallback={<div>Loading Sep WavedMask FrameMask AnimRef ...</div>} 
                                        >
                                            <LazyIntroTextSpravneWebStrankyCCh
                                                colors={optionsConnectTextsColors}
                                            />
                                        </Suspense>
                                    </AnimateScrollIntoViewport>
                                </FrameMaskForDiv>
                            </Container>
                        </WavedFrameAnimationForChildren>
                    </div>
                </SeparatorAbsSvg2>
            </div>

            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: INSET_FULL, }}
                exitFramer={{ clipPath: INSET_2_3, }}
                styleToMerge={{ clipPath: INSET_2_3, }}
                name="sepAfterIntro5"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRelDblFullHorizontal1"
                    id="independentSepNormalRelDblFullHorizontal3"
                    optionsSep={{
                        dPathOrderNotIn: [ 0, 1 ], heightsWanted: [ 8, 16, 32, 48, 64 ],
                        styleWrapSep: {
                            marginTop: 0,
                            marginBottom: 0,
                        }
                    }}
                    key="sepAfterIntro5"
                />
            </AnimateScrollIntoViewport>
            <div style={{
                transform: "translateZ(0)",
                // minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
                id="webpredstava"
                ref={refWebPredstava}
                key="TextWebPredstava"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="IntroTextMatPredstavuWebStrankyCCh"
                    passedControllingInViewport={false}
                    key="IntroTextMatPredstavuWebStrankyCCh"
                    optionsSep={{
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,
                        }}
                    >
                        <Container maxWidth="xl" className='cont'>
                            <AnimateScrollIntoViewport
                                durationIn={0.5}
                                durationOut={0.2}
                                animateFramer={{
                                    clipPath: POLYGON_RECTANGLE, // POLYGON_RECTANGLE_10_POINTS_RIGHT,
                                }}
                                exitFramer={{
                                    clipPath: POLYGON_RECTANGLE_RIGHT_NULL, // POLYGON_STAR,
                                }}
                                styleToMerge={{
                                    clipPath: POLYGON_RECTANGLE_RIGHT_NULL, // POLYGON_STAR, 
                                }}
                                name="maskPredstava"
                                refParent={refWebPredstava}
                            >
                                <FrameMaskForDiv
                                    options={{
                                        frames: [ "right", "bottom", "left" ],
                                        masks: { top: "mask12", right: "mask12V", bottom: "mask12", left: "mask12V" },
                                        rightFr: { isCornerMask: false }, bottomFr: { isCornerMask: false }, leftFr: { isCornerMask: false }
                                    }}
                                >
                                    <Suspense
                                    // fallback={<div>Loading Sep FrameMask AnimRef ...</div>}
                                    >
                                        <LazyIntroTextMatPredstavuWebStrankyCCh />
                                    </Suspense>
                                </FrameMaskForDiv>
                            </AnimateScrollIntoViewport>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </div>
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: INSET_FULL, }}
                exitFramer={{ clipPath: INSET_1_3, }}
                styleToMerge={{ clipPath: INSET_1_3, }}
                name="sepAfterIntro3"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRelDblFullHorizontal1"
                    id="independentSepNormalRelDblFullHorizontal1_4"
                    optionsSep={{
                        dPathOrderNotIn: [ 0, 1 ], heightsWanted: [ 8, 16, 32, 48, 64 ],
                        styleWrapSep: {
                            marginTop: 0,
                            marginBottom: 0,
                        }
                    }}
                    key="sepAfterIntro3"
                />
            </AnimateScrollIntoViewport>
            <div style={{
                transform: "translateZ(0)",
                // minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
                id="web7is"
                // ref={refServices}
                key="web7is"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="ServicesAbout"
                    passedControllingInViewport={false}
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "50px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,
                        }}
                    >
                        <Container maxWidth="md" className='cont'                    >
                            <WavedFrameAnimationForChildren
                                key="ServicesAbout"
                            >
                                <FrameMaskForDiv
                                    styleWrap={{ width: 'fit-content' }}
                                    options={{
                                        styleAll: { mixBlendMode: 'color-burn', },
                                        frames: [ "top", "right", "bottom", "left" ],
                                        masks: { top: "mask3", right: "mask10V", bottom: "mask3", left: "mask10V" },
                                        topFr: { isCornerMask: false, style: { backgroundColor: "success.main" } }, rightFr: { isCornerMask: false }, bottomFr: { isCornerMask: false }, leftFr: { isCornerMask: false }
                                    }}
                                >
                                    <Suspense>
                                        <LazyTypewritingAbout />
                                    </Suspense>
                                </FrameMaskForDiv>
                            </WavedFrameAnimationForChildren>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </div>
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: INSET_FULL, }}
                exitFramer={{ clipPath: INSET_2_3, }}
                styleToMerge={{ clipPath: INSET_2_3, }}
                name="sepAtro1"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRelDblFullHorizontal1"
                    id="independentSepNormalRelDblFullHorizontal1_5"
                    optionsSep={{
                        dPathOrderNotIn: [ 0, 1 ], heightsWanted: [ 8, 16, 32, 48, 64 ],
                        styleWrapSep: {
                            marginTop: 0,
                            marginBottom: 0,
                        }
                    }}
                    key="sepAtro1"
                />
            </AnimateScrollIntoViewport>
            {/* </Suspense> */}
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ x: 0, opacity: 1 }}
                exitFramer={{ x: -300, opacity: 0 }}
                styleToMerge={{ overflow: "inherit" }}
                name="TechStackWithData"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="TechStackWithData"
                    optionsSep={{
                        dPathOrderNotIn: [ 0 ],
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "50px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,
                        }}
                    >
                        {/* <Container maxWidth="lg" > */}
                        <Suspense
                            // fallback={<div>Loading Anim Sep Tech ...</div>}
                            key="susATechStackWithData">
                            <LazyTechStackWithData />
                        </Suspense>
                        {/* </Container> */}
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                durationIn={0.5}
                durationOut={0.1}
                animateFramer={{ clipPath: INSET_FULL, }}
                exitFramer={{ clipPath: INSET_1_3, }}
                styleToMerge={{ clipPath: INSET_1_3, }}
                name="MultipleRowsInfiniteSlider"
                key="MultipleRowsInfiniteSlider"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="sepMultipleRowsInfiniteSlider"
                    optionsSep={{
                        dPathOrderNotIn: [ 0 ],
                        styleWrapSep: {
                            lineHeight: "inherit"
                        },
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative", marginTop: 0, marginBottom: 0, borderRadius: 'unset', backgroundColor: sepFillColor,
                            color: sepFillColorContrastText
                        }}
                    >
                        <Container
                        >
                            <Suspense>
                                <LazyMultipleRowsInfiniteSlider />
                            </Suspense>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>

            <AnimateScrollIntoViewport
                animateFramer={{
                    x: 0,
                    opacity: 1
                }}
                exitFramer={{ x: -200, opacity: 0 }}
                styleToMerge={{ opacity: 0, x: -200, overflow: "inherit" }}
                name="view-CircleZ"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="sepCircleZ"
                    optionsSep={{
                        dPathOrderNotIn: [ 2 ],
                        styleWrapSep: { lineHeight: "inherit" },
                        styleSvg: {
                            height: "40px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText,

                        }}
                    >
                        <Suspense
                            // fallback={<div>Loading Anim Sep CircZ ...</div>}
                            key="susCircleZ">
                            <LazyCircleZ />
                        </Suspense>
                        {/* </Container> */}
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <PortalInBody>
                <TocFm />
            </PortalInBody>

        </Box>
    );
};

export default MainUp;


// https://gist.github.com/Eyas/e9645e74a15bfb99fa7f373365af9a49
// https://blog.eyas.sh/2022/03/react-toc/
// https://blog.openreplay.com/creating-a-table-of-content-widget-in-react


import classNames from "classnames";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import BtnToggleMorphingD from "./BtnToggleMorphingD.js";
import { dOptions as dOptionsBtnToggleMorphingD } from "./BtnToggleMorphingD.js";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import { motion, } from "framer-motion"; // useInView
import WrapperFMAnimatingChildrenHeight from "../WrapperFMAnimatingChildrenHeight.js";
import useLazyRef from "../../../utils/useLazyRef.js";

const stateViewTOC = {
  partial: "partial",
  expanded: "expanded",
  collapsed: "collapsed",
}
const classWrapItemButton = "tocItemWrap"
const classMiddleWrap = "tocItemMiddle"

const sxStyleNav = {
  position: "fixed",
  top: "11rem",
  right: [ "0.25rem", '0.5rem', '1rem' ],
  bgcolor: "primary.main",
  color: "primary.contrastText",
  borderStyle: 'groove',
  maxWidth: [ '330px', '500px', "600px" ],
  fontFamily: 'Inter,"sans-serif"',
  fontSize: [ "0.6rem", ".8rem" ],
  lineHeight: 1,
  gridColumn: "f !important",
  zIndex: 1,
  mx: [ "0.25rem", "0.5rem", "1rem" ],
  borderRadius: "10px",
  "& .headerWrap": {
    padding: '3px',
    borderBottomStyle: 'groove',
    cursor: 'move',
  },
  "& .controls": {
    position: "absolute",
    top: "6px",
    right: ".5rem",
    zIndex: 1
  },
  "& .outer-scroll": {
    borderRadius: "1em",
    padding: "1rem 0",
    width: "inherit",
    maxWidth: "100%",
    height: "auto",
    "&.partial": {
    },
    "&.expanded": {
    },
    "&.collapsed": {
    },
    "& button": {
      display: "block",
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: 700,
      mx: [ '5px', "10px" ],
      my: "2px",
      borderRadius: "1em",
      maxWidth: "95%",
    },
    [ `& .${classMiddleWrap}` ]: {
      marginRight: '5px',
      [ `& .${classWrapItemButton}` ]: {

        '& .tocCont': {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          borderStyle: 'ridge',
          borderWidth: '1px',
          borderColor: "primary.light",
          padding: "2px",
          borderRadius: "5px",
        },
        '& .tocItem': {
          textAlign: "right",
        },
        '& .tocItem-1': {
          fontSize: [ "1rem", '1.2rem' ],
        },
        '& .tocItem-2': {
          fontSize: [ '0.8rem', '1rem' ],
        },
        '& .tocItem-3': {
          fontSize: [ '0.7rem', '0.9rem' ],
        },
        '& .tocItem-4': {
          fontSize: [ '0.6rem', '0.8rem' ],
        },
      },
    },

    "& hr": {
      width: "50%",
    },
  },
  '& .tocHeader': {
    textAlign: 'center',
    fontWeight: 600,
    marginTop: "1.25em",
    marginBottom: ".75em"
  },
}

const delayNav = 1
const partialDefaultHeight = 250
const linkHeightDefault = 50
const maxExpandedHeightDefault = "50vh"
const linkWidthDefault = 300


/** TocFm
 * put into portal
 * generates Table of Contents
 * to appear in TOC must be h1-h6 and have class "toc",
 * To correct nesting appearance add where appropriate class "toc0" to indicate no further children
 * 2 alternatives to get elements: 1: more times document.querySelector; 2: fill complete elements=huge into ref
 */
export default function TocFm({
  tocClassSelector = "toc",
  tocNoChildrenClassSelector = "toc0",
  headingSelector = `h2.${tocClassSelector},h3.${tocClassSelector},h4.${tocClassSelector},h5.${tocClassSelector},h6.${tocClassSelector}`,
  partialHeight = partialDefaultHeight,
  linkHeight = linkHeightDefault,
  maxExpandedHeight = maxExpandedHeightDefault,
  linkWidth = linkWidthDefault,
}) {
  tocClassSelector = tocClassSelector || "toc";
  headingSelector = headingSelector || `h2.${tocClassSelector},h3.${tocClassSelector},h4.${tocClassSelector},h5.${tocClassSelector},h6.${tocClassSelector}` //"h2.toc,h3.toc,h4.toc,h5.toc,h6.toc";

  const strPartialHeight = `${partialHeight}px`
  const refIsObservingStopped = useRef(false)
  const theme = useTheme()
  const isBrowser = typeof window !== "undefined"
  // 2-nd alternative
  // const refArrHeadingEl = useLazyRef(() => Array.from(document.querySelectorAll(headingSelector)))
  // const ids = refArrHeadingEl.current.map((v) => v.id)
  // const [ headingsData ] = useState(() => getNestedHeadingsData(refArrHeadingEl.current)) // setHeadingsData

  // 1-st alternative
  // const [ headingsData ] = useState(() => getNestedHeadingsData(Array.from(document.querySelectorAll(headingSelector)), tocNoChildrenClassSelector))

  // Initialize headingsData with an empty array
  const [ headingsData, setHeadingsData ] = useState([]);

  const [ activeId, setActiveId ] = useState();

  const observingCallback = (entries) => {

    if (!refIsObservingStopped.current) {
      const inViewSet = new Map();
      let idInViewNew

      for (const entry of entries) {
        // entry.target.classList.toggle('inTOCView',);

        if (entry.target.classList.contains('inTOCView')) {
          entry.target.classList.remove('inTOCView');
          entry.target.classList.add('outTOCView');
        } else {
          entry.target.classList.remove('outTOCView');
          entry.target.classList.add('inTOCView');
        }
        entry.isIntersecting
          ? inViewSet.set(entry.target.id, entry.target)
          : inViewSet.delete(entry.target.id);
      }

      const idsInView = Array.from(inViewSet.entries())
        .map(([ id, el ]) => [ id, el.offsetTop ])
        .filter(([ id, _ ]) => !!id);
      if (idsInView.length > 0) {
        // the highest on screen is considered to active one
        idInViewNew = idsInView.reduce((acc, next) => (next[ 1 ] < acc[ 1 ] ? next : acc))[ 0 ]
      }
      idInViewNew && activeId !== idInViewNew && setActiveId(idInViewNew);
    }

  };

  const refIntersectionObserver = useLazyRef(() => new IntersectionObserver(observingCallback, {
    rootMargin: "-30% 0px -30% 0px", // top, right, bottom, left
    thresholds: 0.2,
  }));

  const [ expansion, setExpansion ] = useState(stateViewTOC.collapsed);
  const scrollRef = useRef(null);

  const fitHeightToItems = useCallback(() => setExpansion(stateViewTOC.expanded), []);
  const setDefinedHeight = useCallback(() => setExpansion(stateViewTOC.partial), []);
  const collapse = useCallback(() => setExpansion(stateViewTOC.collapsed), []);

  const clickHandler = useCallback((e, id) => {
    if (isBrowser) {
      // 2-nd alternative 
      // const el1 = refArrHeadingEl.current.find((v, i) => v.id === id)

      // 1-st alternative
      const el = document.getElementById(id)

      refIsObservingStopped.current = true

      window.setTimeout(() => {
        refIsObservingStopped.current = false
        setActiveId(id)
      }, 1000);

      // scrolling takes about under 1s
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [])


  const itemColor = theme.palette.primary.contrastText
  const itemActiveColor = theme.palette.primary.main

  const currentHeightSettings = expansion === stateViewTOC.collapsed ? 0 : expansion === stateViewTOC.partial ? strPartialHeight : "auto"

  useEffect(() => {
    // Create a MutationObserver to watch for changes to the DOM
    const mutationObserver = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // If a new child is added to the DOM, re-run the IntersectionObserver
          Array.from(document.querySelectorAll(headingSelector)).forEach(el => {
            el && refIntersectionObserver.current.observe(el);
          });

          // Update headingsData when new headings are added
          setHeadingsData(getNestedHeadingsData(Array.from(document.querySelectorAll(headingSelector)), tocNoChildrenClassSelector));
        }
      }
    });

    // Start observing the document with the configured parameters
    mutationObserver.observe(document.body, { childList: true, subtree: true });

    // Disconnect the MutationObserver when the component unmounts
    return () => mutationObserver.disconnect();
  }, []);

  const optionsWrapperFMAnimatingChildrenHeight = {
    heightDirectly: currentHeightSettings,
    duration: 0.5,
    delay: delayNav,
    // classNameWrap:
    classNameChild: classWrapItemButton,
    classMiddleWrap: classMiddleWrap,

    styleWrap: { maxHeight: "inherit", maxWidth: "inherit", },
    styleDirectWrap: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: 'fit-content',
    }
  }

  return (
    <Box component={motion.nav}
      drag
      dragElastic={0.2}
      dragSnapToOrigin={true}
      animate={{
        width: expansion === stateViewTOC.collapsed ? "120px" : "auto",

        transition: {
          delay: delayNav
        }
      }}
      aria-label="Table of Contents"
      sx={sxStyleNav}
    >
      <div className="headerWrap">
        <div className="tocHeader">
          TOC
        </div>
        <div className="controls"
          key="controls"
        >
          {expansion !== stateViewTOC.collapsed && (
            <BtnToggleMorphingD
              onClick={expansion === stateViewTOC.partial ? fitHeightToItems : setDefinedHeight}
              d1={dOptionsBtnToggleMorphingD.UnfoldLessIcon.d}
              d2={dOptionsBtnToggleMorphingD.UnfoldMoreIcon.d}
              svgViewBox={dOptionsBtnToggleMorphingD.UnfoldLessIcon.svgViewBox}
            />
          )}
          <BtnToggleMorphingD
            onClick={expansion !== stateViewTOC.collapsed ? collapse : setDefinedHeight}
            d1={dOptionsBtnToggleMorphingD.CloseFullscreenIcon.d}
            d2={dOptionsBtnToggleMorphingD.OpenInFullIcon.d}
            svgViewBox={dOptionsBtnToggleMorphingD.CloseFullscreenIcon.svgViewBox}

          />
        </div>
      </div>
      <div
        ref={scrollRef}
        className={classNames("outer-scroll"
          , {
            expanded: expansion === stateViewTOC.expanded,
            collapsed: expansion === stateViewTOC.collapsed,
            partial: expansion === stateViewTOC.partial,
          }
        )}

        style={{
          maxHeight: maxExpandedHeight,
        }}
      >
        <WrapperFMAnimatingChildrenHeight options={optionsWrapperFMAnimatingChildrenHeight} >
          {headingsData
            && <>
              {headingsData.map((h, i) => {
                return (
                  <TocItem
                    key={`toc-${h.id}-${i}`}
                    headingData={h}
                    activeId={activeId}
                    clickHandler={clickHandler}
                    itemActiveColor={itemActiveColor}
                    itemColor={itemColor}
                    isPartial={expansion === stateViewTOC.partial}
                    linkHeight={linkHeight}
                    linkWidth={linkWidth}
                    count={0}
                  />
                )
              })}
            </>
          }
        </WrapperFMAnimatingChildrenHeight>
      </div>
    </Box>
  );
}



function TocItem({
  itemActiveColor,
  itemColor,
  headingData: h,
  activeId,
  clickHandler,
  isPartial,
  linkHeight,
  linkWidth,
  count
}) {

  const isActive = h.id === activeId

  const refA = useRef(null);

  useEffect(() => {
    if (isPartial && isActive && refA?.current) {
      refA.current.scrollIntoView()
    }
  }, [ isActive, isPartial ]); //inViewId

  const tocItemButton = <motion.button
    className={`tocItem tocItem-${h.level}`}
    ref={refA}
    animate={isActive && {
      backgroundColor: itemColor,
      color: itemActiveColor,
      opacity: 1,
    }}
    style={{
      width: `${linkWidth - h.level * 20 - 10}px`,
      // fontSize: `${1.2 - h.level * 0.1}rem`,
      // height: strLinkHeight,
      backgroundColor: itemActiveColor,
      color: itemColor,
    }}
    onClick={(e) => clickHandler(e, h.id)}
    key={h.id}
  >
    {h.text}
  </motion.button>;


  return (
    <>

      {count === 0 && (
        <div>
          <hr />
          {/* {h.items} */}
        </div>
      )
      }
      {h.items ? (
        <div
          className={`tocCont tocCont-${h.level}`}
          key={`${h.id}`}
        >
          {tocItemButton}
          {h.items?.map((item) => (
            <TocItem
              headingData={item}
              activeId={activeId}
              clickHandler={clickHandler}
              itemActiveColor={itemActiveColor}
              itemColor={itemColor}
              isPartial={isPartial}
              linkHeight={linkHeight}
              linkWidth={linkWidth}
              count={count + 1}
              key={`toc-${item.id}`}
            />
          ))}
        </div>
      ) : tocItemButton
      }

    </>
  );
}

function getNestedHeadingsData(arrHeadings, tocNoChildrenClassSelector) {
  // adding class "toc0" besides "toc" tells there is no children to this heading element - the must for func to provide expected result
  const arrNestedHeadings = []
  for (const h of arrHeadings) {
    const isEnd = h.classList.contains(tocNoChildrenClassSelector)
    const hLevel = level(h);
    const H = {
      text: h.textContent || "",
      id: h.id,
      level: hLevel,
      isEnd
    }
    // if (!isEnd) {
    const arrRev = [ ...arrNestedHeadings ].reverse()
    const indexOfFirstHigherLevelInRev = arrRev.findIndex((v, i) => v.level < hLevel)
    if (indexOfFirstHigherLevelInRev === -1) {
      arrNestedHeadings.push(H)
      continue
    }
    const indexOfLastHigherLevel = arrRev.length - 1 - arrRev.findIndex((v, i) => v.level < hLevel)
    const indexOfLastEnding = arrRev.length - 1 - arrRev.findIndex((v, i) => v.isEnd === true)

    if (indexOfLastHigherLevel > indexOfLastEnding) {
      if (!arrNestedHeadings[ indexOfLastHigherLevel ].items) {
        arrNestedHeadings[ indexOfLastHigherLevel ].items = []
      }
      arrNestedHeadings[ indexOfLastHigherLevel ].items.push(H)
    } else {
      arrNestedHeadings.push(H)
    }
  }
  return arrNestedHeadings
}

// use parseInt(e.tagName[1]) to get a numeric representation of the heading level h2, h3, h4.... The goal is to slot deeper headings in the item array of the shallower headings.
function level(e) {
  return parseInt(e.tagName[ 1 ]);
}




// https://www.youtube.com/watch?v=G3OyF-lRAWo
import React from 'react'
import useResizeObserver from "use-resize-observer";
import { motion } from "framer-motion";
import flattenChildren from "react-keyed-flatten-children";

const outerAnimationDefault = {}
const outerTransitionDefault = { duration: 0.5 }
const innerAnimationDefault = {}
const innerTransitionDefault = { duration: 0.5, }


function WrapperFMAnimatingChildrenHeight({ children, options }) {
    // console.log("============================= WrapperFMAnimatingChildrenHeight ============start scroll")

    const {
        heightDirectly = "auto",
        isAnimateWidth = true,
        widthDirectly = false,
        outerAnimation = outerAnimationDefault,
        outerTransition = outerTransitionDefault,
        innerAnimation = innerAnimationDefault,
        innerTransition = innerTransitionDefault,
        delay = 0,
        isItemAnimation = true,
        classNameWrap = "wrapperFMAnimatingChildrenHeight",
        classMiddleWrap = "midWrap",
        classNameChild = 'wrapChild',
        styleWrap = {},
        styleMiddleWrap = {},
        styleWrapChild = {}
    } = options

    const { ref, height } = useResizeObserver(); // width = 1, 

    const outerDelay = delay - innerTransition.duration
    const heightUsed = heightDirectly === "auto" ? height + 20 : heightDirectly
    const widthUsed = heightUsed === 0 ? "auto" : widthDirectly === false ? "auto" : widthDirectly
    const widthAnimation = isAnimateWidth ? {
        width: widthUsed || "auto",
    } : {}

    // console.log("end================== WrapperFMAnimatingChildrenHeight ============ scroll")
    return (
        <motion.div
            layout
            className={classNameWrap}
            style={{
                ...styleWrap,
                overflow: 'auto',
            }}
            animate={{
                ...outerAnimation,
                ...widthAnimation,
                height: heightUsed,

            }}
            transition={{
                ...outerTransition,
                delay: outerDelay
            }}
        >
            <motion.div
                ref={ref}
                key={JSON.stringify(children, ignoreCircularReferences())}
                layout
                animate={{
                    ...innerAnimation,
                    x: heightUsed === 0 ? 300 : 0,
                    opacity: heightUsed === 0 ? 0 : 1,
                }}
                transition={{
                    ...innerTransition,
                    when: "afterChildren",
                }}
                className={classMiddleWrap}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'flex-end',
                    ...styleMiddleWrap,
                }}
            >

                {isItemAnimation ?
                    flattenChildren(children).map((child, i) => {
                        return (
                            <motion.div
                                layout
                                animate={{
                                    x: heightUsed === 0 ? -100 : 0,
                                    rotateZ: heightUsed === 0 ? 90 : 0,
                                }}
                                transition={{
                                    delay: 0.02 * i,
                                    duration: 0.5,
                                }}
                                custom={i}
                                className={classNameChild}
                                style={{
                                    display: "flex",
                                    flexDirection: "inherit",
                                    alignItems: "inherit",
                                    ...styleWrapChild,
                                }}
                                key={`child-${i}`}
                            >
                                {child}
                            </motion.div>
                        )
                    }) :
                    children
                }
            </motion.div>
        </motion.div>
    );
}

/*
  Replacer function to JSON.stringify that ignores
  circular references and internal React properties.
  https://github.com/facebook/react/issues/8669#issuecomment-531515508
*/
const ignoreCircularReferences = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (key.startsWith("_")) return; // Don't compare React's internal props.
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) return;
            seen.add(value);
        }
        return value;
    };
};


export default WrapperFMAnimatingChildrenHeight
// https://blog.thoughtspile.tech/2021/11/30/lazy-useref/
import { useRef } from "react";
const none = {};
function useLazyRef(fnInitValueForRef) {
    // not initialized yet
    const ref = useRef(none);
    // if it's not initialized (1st render)
    if (ref.current === none) {
        // we initialize it
        ref.current = fnInitValueForRef();
    }
    // new we return the initialized ref
    return ref;
}

export default useLazyRef